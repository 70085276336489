.pscreen {}

.pscreen h1 {
    font-size: 50px;
    color: #fff;
}

.pscreen h1 span {
    color: #B5EAD7;
}

.pscreen .setup-btn {
    font-size: 18px;
    border-radius: 20px;
}

.pscreen ul {
    font-size: 18px;
}

.pscreen ul li:before {
    content: '✓ ';
}

@media screen and (max-width: 860px) {
    .pscreen h1 {
        width: 90%;
        font-size: 35px;
        color: #fff;
        text-align: center;
    }

    .pscreen ul {
        font-size: 15px;
    }

    .pscreen img {
        width: 100%;
    }

    .pscreen .setup-btn {
        width: 80%;
    }
}