@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
html {
    overflow: scroll;
    overflow-x: hidden;
}
body {
    -webkit-tap-highlight-color: transparent;
    background: linear-gradient(#000, #222831, #000);
    font-family: "Jost", sans-serif;
}
::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #101427;
}