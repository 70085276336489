@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
nav {
	position: fixed;
	z-index: 10;
	left: 0;
	right: 0;
	top: 0;
	font-family: 'Montserrat', sans-serif;
	padding: 0 5%;
	height: 60px;
	background-color: #222831;
	display: flex;
	align-items: center;
    /* backdrop-filter: blur(15px); */
}
nav .logo {
	float: left;
	width: 40%;
	height: 100%;
	margin: 10px 0;
	font-size: 24px;
	color: #fff;
	font-family: "Jost", sans-serif;
}
nav .logo a{
	color: #fff;
}
nav .links {
	float: right;
	padding: 0;
	margin: 0;
	/* width: 60%; */
	height: 100%;
	display: flex;
	/* justify-content: space-around; */
	/* align-items: center; */
}
nav .links li {
	list-style: none;
	display: flex;
	align-items: center;
}
nav .links a {
	display: block;
	padding: 1em;
	font-size: 14px;
	font-weight: bold;
	color: #fff;
	text-decoration: none;
}
nav .links button {
    background-color: #B5EAD7;
    font-weight: bold;
    padding: 3px 13px;
    border: 0;
    border-radius: 5px;
}
#nav-toggle {
	position: absolute;
	top: -100px;
}
nav .icon-burger {
	display: none;
	position: absolute;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}
nav .icon-burger .line {
	width: 25px;
	height: 3px;
	background-color: #fff;
	margin: 5px 0;
    /* margin-right: -5px; */
	border-radius: 3px;
	transition: all .3s ease-in-out;
}
@media screen and (max-width: 768px) {
	nav .logo {
		float: none;
		width: auto;
		/* justify-content: center; */
	}
	nav .links {
		float: none;
		position: fixed;
		z-index: 9;
		left: 0;
		right: 0;
		top: 60px;
		bottom: 100%;
		width: auto;
		height: auto;
		flex-direction: column;
		/* justify-content: space-evenly; */
		text-align: right;
		background-color: rgba(0,0,0,.8);
        backdrop-filter: blur(10px);
		overflow: hidden;
		box-sizing: border-box;
		transition: all .5s ease-in-out;
	}
	nav .links li {
		display: block;
	}
	nav .links a {
		font-size: 20px;
	}
	nav :checked ~ .links {
		bottom: 0;
	}
	nav .icon-burger {
		display: block;
	}
	nav :checked ~ .icon-burger .line:nth-child(1) {
		transform: translateY(6px) rotate(225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(3) {
		transform: translateY(-10px) rotate(-225deg);
	}
	nav :checked ~ .icon-burger .line:nth-child(2) {
		opacity: 0;
	}
	
}
